import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col, Table } from 'react-bootstrap';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';

const HSRGuidesRelicFarmPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page '} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>What Relic Sets to farm?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_relicfarm.png"
            alt="What Relic Sets to farm?"
          />
        </div>
        <div className="page-details">
          <h1>What Relic Sets to farm?</h1>
          <h2>The best sets you can farm in Honkai: Star Rail early game.</h2>
          <p>
            Last updated: <strong>05/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Relic farming in Honkai: Star Rail is an important part of your
          account progression and you will spend a lot of your time and stamina
          past TL 40 doing the so called Relic Farm.
        </p>
        <p>
          Still, considering that both in the Cavern of Corrosion and in the
          Simulated Universe{' '}
          <strong>
            the available Relic Sets are split between various stages (or Worlds
            in the case of SU) you are forced to choose which ones you want to
            farm
          </strong>
          . While all the Relic Sets have their uses and there's no useless one
          (well...), you have to pick a pair that will help your account the
          most. And that's what this guide is about - we will review each set
          and give you suggestion for the best Cavern of Corrosion and Simulated
          Universe World to farm!
        </p>
        <SectionHeader title="Cavern of Corrosion" />
        <p>
          <strong>Important!</strong> Before you start farming Cavern of
          Corrosion you have to reach TL 40 as this unlocks Difficulty 3 where
          you can get 1 Gold rarity Relic per run. You will get enough Purple
          rarity Relics from the story, so farming them is a waste of Stamina.
        </p>
        <p>
          Also, please keep in mind that the reviews and 'ratings' below take
          into account the current state of the game - maybe in the future the
          priority will change and if it will, we will adjust the information
          below.
        </p>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Cavern</th>
              <th>Set 1</th>
              <th>Set 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Path of Gelid Wind</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Hunter of Glacial Forest" compactMode />
                  </Col>
                </Row>
                <p>
                  This set is currently not a BiS option for any character due
                  to its low boosts - 25% Crit Damage for 2 turns. As most Ice
                  characters have high energy cost Ultimates, they prefer
                  2-piece combinations or other sets altogether.
                </p>
                <p>
                  <strong>Farming worth: 1/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Eagle of Twilight Line" compactMode />
                  </Col>
                </Row>
                <p>
                  For the Supports lacking synergistic 4-piece options, Eagle of
                  Twilight works well as a min-max option for additional actions
                  - especially for those aiming for 0-Cycle clears in Memory of
                  Chaos. DPS characters who frequently make use of Ultimates,
                  such as Feixiao and Boothill, can also make use of Action
                  Advance for faster clears.
                </p>
                <p>
                  <strong>Farming worth: 5/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Jabbing Punch</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Champion of Streetwise Boxing"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A pretty niche set - even most Physical damage dealers don't
                  want to use it as they have some better options, so it's not
                  really worth farming.
                </p>
                <p>
                  <strong>Farming worth: 3/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Thief of Shooting Meteor" compactMode />
                  </Col>
                </Row>
                <p>
                  A decent set for Breakers, but it got overshadowed by the Iron
                  Cavelry one.
                </p>
                <p>
                  <strong>Farming worth: 2/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Drifting</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Passerby of Wandering Cloud"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  Its 4-piece bonus is always appreciated in teams having
                  frontloaded SP usage in the first turn, whereas all the
                  Healers can utilize 2-piece bonus for additional healing.
                  Generally, farming Relics for Healers results in minimal gains
                  in team performance, as such farming for the main stat is
                  enough.
                </p>
                <p>
                  <strong>Farming worth: 2/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Musketeer of Wild Wheat" compactMode />
                  </Col>
                </Row>
                <p>
                  There are only a few characters that prefer the 4-piece
                  Musketeer of Wild Wheat set - as it is a rather small
                  improvement over its 2-piece combos, which are also easier to
                  match for better sub-stats. Nowadays, you can find 2-piece
                  Speed/ATK/DMG% bonuses on different sets leading to 4-piece
                  Musketeer losing its purpose.
                </p>
                <p>
                  <strong>Farming worth: 2/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Providence</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Guard of Wuthering Snow" compactMode />
                  </Col>
                </Row>
                <p>
                  A bit underwhelming set that is mostly used as 2-piece for
                  some tankier characters. The 4-piece set isn't useful on
                  anyone at the moment.
                </p>
                <p>
                  <strong>Farming worth: 3/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Genius of Brilliant Stars" compactMode />
                  </Col>
                </Row>
                <p>
                  Best in Slot for any Quantum damage dealer - the difference
                  between this set and the others is simply massive. Still, you
                  can also use this set on any other element dps, simply because
                  the DEF ignore mechanic is among the strongest in the game and
                  even if you can only activate the 10%, it still can compete
                  with other dedicated DPS sets, but only when you provide
                  additional sources of DEF shred into the team (via Pela for
                  example).
                </p>
                <p>
                  Also keep in mind that on non-Quantum dps you need to face
                  Quantum-weak enemies to make it worth using.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Holy Hymn</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Knight of Purity Palace" compactMode />
                  </Col>
                </Row>
                <p>
                  The strongest set for Preservation characters as it not only
                  gives the the DEF they need to make their Shields chunkier,
                  but also the 4-piece bonus acts as a separate modifier that
                  you can't find anywhere else in the game.
                </p>
                <p>
                  <strong>Farming worth: 6/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Band of Sizzling Thunder" compactMode />
                  </Col>
                </Row>
                <p>
                  A decent set on paper, but sadly while in the past it was used
                  on a lot of characters, nowadays it's losing to other, more
                  dedicated sets - even on Lightning DPS.
                </p>
                <p>
                  <strong>Farming worth: 2/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Conflagration</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Firesmith of Lava-Forging" compactMode />
                  </Col>
                </Row>
                <p>
                  Another underwhelming set that is rarely used even on Fire DPS
                  characters.
                </p>
                <p>
                  <strong>Farming worth: 1/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Wastelander of Banditry Desert"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A great set for Imaginary damage dealers, but you won't be
                  able to use them on any other character in the game. So farm
                  it only if you often use IMG DPS (but they also have other
                  alternatives).
                </p>
                <p>
                  <strong>Farming worth: 3/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Elixir Seekers</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Longevous Disciple" compactMode />
                  </Col>
                </Row>
                <p>
                  A bit niche set that is Best in Slot for Blade, but the
                  2-piece effect can actually be used also by Healers and
                  Supports.
                </p>
                <p>
                  <strong>Farming worth: 5/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Messenger Traversing Hackerspace"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  This set is loved by Support characters - as 6% Speed boost on
                  2-piece is significant and allows you to mix-match the set
                  with others easily, allowing for more flexibility. Though, the
                  4-piece effect makes little difference in most scenarios due
                  to lasting for only 1 turn and can even mess up Speed tuning
                  for the team sometimes.
                </p>
                <p>
                  <strong>Farming worth: 10/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Darkness</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Prisoner in Deep Confinement"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  The Best in Slot set for any DoT (Damage over Time) based
                  character - the ignore DEF mechanic is simply too strong and
                  in DoT teams it's easy to hit the 3 DoT number to activate the
                  full bonus. Useless for everyone else, so only farm this set
                  if you're building a DoT team. The 2-piece is also pretty
                  universal and can be used as placeholder for other characters.
                </p>
                <p>
                  <strong>Farming worth: 6/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="The Ashblazing Grand Duke" compactMode />
                  </Col>
                </Row>
                <p>
                  A great option for Follow-Up characters, but even if a
                  character only has a single Follow-Up in their kit, the set
                  actually still can be Best in Slot for them as it allows to
                  easily keep the secondary bonus up for the majority of the
                  fight - that's the case for Jing Yuan for example.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Dreamdive</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Pioneer Diver of Dead Waters"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  Acheron's best in slot set, but it can also be used on other
                  damage dealers. The 2-piece is easy to activate as you usually
                  run a debuffer and the 4-piece is super powerful on DPS
                  characters that can apply debuffs to activate the 100% bonus.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Watchmaker, Master of Dream Machinations"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  Best in slot set for Break supports that is very worth farming
                  as Break is the current meta archetype.
                </p>
                <p>
                  <strong>Farming worth: 10/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Path of Cavelier</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="The Wind-Soaring Valorous" compactMode />
                  </Col>
                </Row>
                <p>
                  This set activation conditions are very specific - as not only
                  you need to be able to launch a Follow-Up Attack, but also
                  your Ultimate’s damage should be the main source of damage. As
                  of 2.6 patch, only Feixiao and Yunli can benefit from the
                  4-piece effect.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Iron Cavalry Against the Scourge"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  Best in slot set for Break damage dealers like Firefly or
                  Boothill.
                </p>
                <p>
                  <strong>Farming worth: 10/10</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Simulated Universe" />
        <p>
          <strong>Important!</strong> Before you start farming Simulated
          Universe you have to reach TL 40 as this unlocks higher difficulty in
          the earlier Worlds which drops Gold gear.
        </p>
        <p>
          Also, please keep in mind that the reviews and 'ratings' below take
          into account the current state of the game - maybe in the future the
          priority will change and if it will, we will adjust the information
          below.
        </p>
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>World</th>
              <th>Set 1</th>
              <th>Set 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>3</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Space Sealing Station" compactMode />
                  </Col>
                </Row>
                <p>
                  A great DPS set, especially in the early game where you don't
                  have enough Crit Rate to trigger Salsatto or Rutilant
                  secondary effects. It's struggling in the late game due to
                  lack of Crit and oversaturation of ATK% buffs on supports.
                </p>
                <p>
                  <strong>Farming worth: 5/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Fleet of the Ageless" compactMode />
                  </Col>
                </Row>
                <p>
                  A great and universal support set that can be used on both
                  Supports and Healers and it will make them tankier, and
                  providing a buff that almost every damage dealer and team can
                  make use of.
                </p>
                <p>
                  <strong>Farming worth: 5/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>4</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Talia: Kingdom of Banditry"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A great set for Breakers and since now they are pretty meta,
                  it's worth farming.
                </p>
                <p>
                  <strong>Farming worth: 7/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Sprightly Vonwacq" compactMode />
                  </Col>
                </Row>
                <p>
                  Sprightly Vonwaq is a set that has found it's niche for
                  players that min max their teams. The main use of this set is
                  it's action advancement to force supportive units to move
                  first before damage dealers applying their debuffs or buffs to
                  ensure an explosive opening turn. This set is still only
                  recommended for those aiming to speed clear endgame in the
                  fastest time possible (0 Cycles).
                </p>
                <p>
                  <strong>Farming worth: 3/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>5</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Pan-Cosmic Commercial Enterprise"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A Debuffer set that will help them land their Debuffs, but
                  also improve their damage output thanks to the second part of
                  the set. Sadly, it's very niche and inferior to other sets for
                  most characters.
                </p>
                <p>
                  <strong>Farming worth: 3/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Celestial Differentiator" compactMode />
                  </Col>
                </Row>
                <p>
                  With the release of better CRIT sets, this set is now
                  basically useless.
                </p>
                <p>
                  <strong>Farming worth: 1/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>6</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Belobog of the Architects" compactMode />
                  </Col>
                </Row>
                <p>
                  A powerful set for Preservation characters that will make
                  their Shields even chunkier, but nowadays a lot of players
                  prefer to run more supportive sets on their Shielders -
                  sacrificing a bit Shield value to gain additional team
                  benefits.
                </p>
                <p>
                  <strong>Farming worth: 2/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Inert Salsotto" compactMode />
                  </Col>
                </Row>
                <p>
                  More Crit Rate is always nice, and the additional bonuses are
                  actually really strong on characters that use follow-up
                  attacks like Clara, Jing Yuan, Topaz and Dr. Ratio.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>7</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Broken Keel" compactMode />
                  </Col>
                </Row>
                <p>
                  A great set that can be used on any support to give the main
                  carry free CRIT DMG. Just keep in mind you need 30% Effect RES
                  to activate the secondary effect.
                </p>
                <p>
                  <strong>Farming worth: 9/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet name="Rutilant Arena" compactMode />
                  </Col>
                </Row>
                <p>
                  An alternative to Salsotto, but with steeper Crit Rate
                  requirements to trigger the second part. Great on characters
                  that gain a large portion of their damage from Basic and Skill
                  like Blade, Imbibitor Lunae or Jingliu.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>8</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Firmament Frontline: Glamoth"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A great option for DoT characters as they generally build
                  Speed, while also being a generally good option for most DPS.
                  However, the lack of Crit bonuses and high Speed requirements
                  makes it unfavorable for Crit-oriented characters.
                </p>
                <p>
                  <strong>Farming worth: 7/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Penacony, Land of the Dreams"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A sidegrade to Vonwacq, depending on the teams you're running.
                </p>
                <p>
                  <strong>Farming worth: 6/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>9</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Izumo Gensei and Takama Divine Realm"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  One of the most powerful planar sets in the game with
                  exceptional stats but with a heavy requirement that the wearer
                  must be in a team alongside another character of the same
                  path. This set is only optimal for damage characters that have
                  one of their best compositions including another path member.
                  Currently the amount of characters with teams that satisfy
                  this condition is low making this set powerful but niche at
                  this time.
                </p>
                <p>
                  <strong>Farming worth: 7/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Sigonia, the Unclaimed Desolation"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  A set that relies on slaying enemies quickly and at the
                  beginning of the fight to ensure a competitive performance.
                  This requirement is easy to satisfy in Pure Fiction and as a
                  result this set is excellent in that mode but falls short in
                  other modes with less monsters to slay like Memory of Chaos.
                  If you want to power up your Pure Fiction team this set can be
                  good but outside of this it's a niche choice.
                </p>
                <p>
                  <strong>Farming worth: 4/10</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>10</strong>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Forge of the Kalpagni Lantern"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  Best in Slot set for Firefly, but it can also be used on other
                  characters in her team like Gallagher and Lingsha.
                </p>
                <p>
                  <strong>Farming worth: 8/10</strong>
                </p>
              </td>
              <td>
                <Row xs={1} className="relic-row farming">
                  <Col>
                    <HSRRelicSet
                      name="Duran, Dynasty of Running Wolves"
                      compactMode
                    />
                  </Col>
                </Row>
                <p>
                  An amazing set for Follow-Up Attackers, offering unmatched
                  bonuses after a small ramp up time. Since Follow-Up Characters
                  are often used together, it is worth farming multiple sets for
                  a Follow-Up Attack based team.
                </p>
                <p>
                  <strong>Farming worth: 9/10</strong>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesRelicFarmPage;

export const Head: React.FC = () => (
  <Seo
    title="What Relic Sets to farm? | Honkai: Star Rail | Prydwen Institute"
    description="The best sets you can farm in Honkai: Star Rail early game."
  />
);
